// npm
import React from 'react'
import { graphql, PageProps } from 'gatsby'

// root
import { pageContextProps } from '../../gatsby-node'

// components
import ArtworkLandingLayout from '@templates/layouts/ArtworkLandingLayout'

interface Props {
  data: Queries.ArtworkLandingQuery
  pageContext: PageProps & pageContextProps
}

const ArtworkLanding = ({ data, pageContext }: Props) => {
  return (
    <ArtworkLandingLayout
      artworks={data.allDatoCmsArtwork.edges}
      pageContext={pageContext}
    />
  )
}

export const query = graphql`
  query ArtworkLanding($skip: Int!, $perPage: Int!) {
    allDatoCmsArtwork(
      limit: $perPage
      skip: $skip
      sort: {
        fields: [availability___position, meta___createdAt]
        order: [ASC, DESC]
      }
    ) {
      edges {
        node {
          ...artworkCard
        }
      }
    }
  }
`

export default ArtworkLanding
